import ApplicationController from '../../javascript/controllers/application'

export default class extends ApplicationController {

  static classes = [
    'hamburgerMenuClose',
    'hamburgerMenuOpen'
  ]

  static outlets = []

  static targets = [
    'menuIconHamburger',
    'menuIconX',
    'hamburgerMenu'
  ]

  static values = {
    hamburgerMenuVisible: Boolean
  }

  initialize() {}

  connect() {}

  // ==== Actions

  toggleHamburgerMenu() {
    if (this.hamburgerMenuVisibleValue === true) {
      this.hamburgerMenuVisibleValue = false
    } else {
      this.hamburgerMenuVisibleValue = true
    }
  }

  // ==== Callbacks

  hamburgerMenuVisibleValueChanged() {
    this.refreshHamburgerMenu()
  }

  // ==== Private

  refreshHamburgerMenu() {
    if (this.hamburgerMenuVisibleValue === true) {
      this.menuIconHamburgerTarget.classList.remove(this.hamburgerMenuOpenClass)
      this.menuIconHamburgerTarget.classList.add(this.hamburgerMenuCloseClass)

      this.menuIconXTarget.classList.add(this.hamburgerMenuOpenClass)
      this.menuIconXTarget.classList.remove(this.hamburgerMenuCloseClass)

      this.hamburgerMenuTarget.classList.add(this.hamburgerMenuOpenClass)
      this.hamburgerMenuTarget.classList.remove(this.hamburgerMenuCloseClass)
    } else {
      this.menuIconHamburgerTarget.classList.add(this.hamburgerMenuOpenClass)
      this.menuIconHamburgerTarget.classList.remove(this.hamburgerMenuCloseClass)

      this.menuIconXTarget.classList.remove(this.hamburgerMenuOpenClass)
      this.menuIconXTarget.classList.add(this.hamburgerMenuCloseClass)

      this.hamburgerMenuTarget.classList.remove(this.hamburgerMenuOpenClass)
      this.hamburgerMenuTarget.classList.add(this.hamburgerMenuCloseClass)
    }
  }

}
