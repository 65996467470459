import '@hotwired/turbo-rails'
// import 'flowbite'
import 'what-input'

import './alpinejs'
import './controllers'
import './service-worker-companion'
import '../components'

Turbo.StreamActions.full_page_redirect = function() {
  document.location = this.getAttribute('target')
}
