import ApplicationController from '../../javascript/controllers/application'

export default class extends ApplicationController {

  static classes = []

  static outlets = []

  static targets = []

  static values = {
    autoClear: Boolean
  }

  initialize() {}

  connect() {
    if (this.autoClearValue) {
      setTimeout(() => {
        this.close()
      }, 10000)
    }
  }

  // ==== Actions

  close() {
    this.element.classList.add('fade-out')
    setTimeout(() => this.element.remove(), 1000)
  }

  // ==== Callbacks

  // ==== Private

}
