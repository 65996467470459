import ApplicationController from './application'

export default class extends ApplicationController {

  static classes = []

  static outlets = []

  static targets = []

  static values = {}

  // initialize() {}

  // connect() {}

  // ==== Actions

  remove(event) {
    const element = event.currentTarget
    element.closest('tr').remove()
  }

  // ==== Callbacks

  // ==== Private

}
